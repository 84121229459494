<p>users works!</p>


<table>
    <thead>
        <th>Name</th>
    </thead>
    <tbody>
        <tr *ngFor="let site_user of user_list">

            <td><a routerLink="/admin/users/{{ site_user['_id'] }}"> {{site_user['title']}} </a></td>
        </tr>
    </tbody>
</table>
<h1 mat-dialog-title>Login to unlockd</h1>
<div *ngIf="signinLightning" mat-dialog-content>
    <div *ngIf="loginmodebrowser">
        <p><a [attr.href]="weblnurl" class="webln-button d-none btn btn-primary mx-auto" >Connect to browser</a></p>
        <p>If the link above does not work for you, please install the <a href="https://getalby.com">getalby web extension.</a></p>
        <p>Or, scan a QR code using <a href="https://coincharge.io/en/lnurl-for-lightning-wallets/">your favorite lightning wallet</a> that supports lnurl-auth </p>
        
    </div> 
    <div *ngIf="loginmodeQR">Login with a QR code.
    <p> Scan this QR code with <a href="https://coincharge.io/en/lnurl-for-lightning-wallets/">your favorite lightning wallet</a> that supports lnurl-auth</p>. 
        <a class="qr-link" href="">
        <img class="qr" id="qr_image" [src]=qrCode >
      </a>
    </div> 
    <!----
    <div *ngIf="loginmodeEmail">Login with an email address.
        <p> Enter your email address to recieve a temporary login code</p>. 
        <p> This login functionality is provided as a temporary convenience, and will no longer be available once lnurl-auth is working on iphone/safari.  Please use lightning.</p>
            
        Email address: <input type="email"  [(ngModel)]="emailaddress" email>
        <button mat-raised-button color="primary" (click)="submitEmailNoLightning()">Submit Email</button>

    </div> 
    -->
</div>
<div *ngIf="signinEmailValidationStart" mat-dialog-content>
    <p>You are logged in with lightning.  </p>
    <h1 mat-dialog-title>Optional: Validate email</h1>
    <p>Connect your lightning login with an email address for additional features.  Account recovery, gamer handle, group membership, developer tools, and more.</p>
    <p>We will never sell your email address, or give it away to anyone.</p>
    <p>
        Email address: <input type="email"  [(ngModel)]="emailaddress" email>
    </p>
    <p> {{emailaddress}}</p>
</div>
<div *ngIf="signinEmailValidationCodeStart" mat-dialog-content>
    <p>Please check your email for the 6 digit security code  </p>
    <h1 mat-dialog-title>Optional: Validate email</h1>

    <p>
    VerificationCode: <input type="text"  [(ngModel)]="emailaddressverificationcode" >
    </p>
    <p> {{emailaddressverificationcode}}</p>
</div>


<div *ngIf="signinLightning" mat-dialog-actions>
    <div class="login-button-row">
        <button *ngIf="!loginmodebrowser" mat-raised-button color="primary" (click)="changeLoginMode('browser')">Login with browser</button>
        <button *ngIf="!loginmodeQR" mat-raised-button color="accent" (click)="changeLoginMode('qrcode')">Login with QR code</button>
        <!--<button *ngIf="!loginmodeEmail" mat-raised-button color="accent" (click)="changeLoginMode('email')">Login with Email</button> -->
        <button mat-raised-button color="warn" mat-dialog-close>Close</button>
    </div>
</div>
<div *ngIf="signinEmailValidationStart" mat-dialog-content>
    <div class="login-button-row">
        <button mat-raised-button color="primary" (click)="submitEmail()">Submit Email</button>
        <button mat-raised-button color="warn" mat-dialog-close>Close</button>
    </div>
</div>
<div *ngIf="signinEmailValidationCodeStart" mat-dialog-content>
    <div class="login-button-row">
        <button *ngIf="!loginmodeEmail" mat-raised-button color="primary" (click)="validateEmail()">Verify Email</button>
        <button *ngIf="loginmodeEmail" mat-raised-button color="primary" (click)="validateEmailNoLightning()">Verify Email NL</button>
        <button mat-raised-button color="warn" mat-dialog-close>Close</button>
    </div>
</div>


    <div id="container">
          <div id="recent">
              <div class="tag">Now</div>
              <div class="fourcolcontent post">
                  <h3><a href="#">gaming unlockd</a></h3>
  
  Open source, decentralized, bitcoin powered tools for modern multiplayer and competitive games.<br>
  Run your own multiplayer game backend, and customize it however you want.
  
              </div>
              <div class="fourcolside" id="comments">
                  <h5><a href="#">21</a></h5>
              </div>
              <div class="clear"></div>
          </div>
  
          <div class="divider"></div>
  
          <div id="older">
              <div class="tag">Mission</div>
              <div class="fourcolside post">
                  <h3>Bitcoin Only</h3>
          We are exclusively focused on bitcoin.  No site token, no shi*coins.  BTC and lightning ftw.
              </div>
              <div class="fourcolside post">
                  <h3>Support developers</h3>
                  Indie and small studios need backend game solutions.  Providing complete open source access allows devs and studios to run a customized backend.
              </div>
              <div class="fourcolside post">
                  <h3>Promote decentralization</h3>
          Multiplayer games still require high performance, realtime, dedicated servers.  Especially in the case of competitive games, or games with an economy, these servers need to be under control of the game developer.
              </div>
              <div class="fourcolside post">
                  <h3>Encourage self custody</h3>
                  We remind users to withdraw sats from the site at the end of a gaming session.  We will provide help and instructions on how to do this.  Lightning makes deposit and withdraw quick and easy.
              </div>
              <div class="clear"></div>
          </div>
  
  
      <div class="divider"></div>
  
          <div id="older">
              <div class="tag">TASKS</div>
              <div class="fourcolside post">
                  <h3>Convert backend to python 3 runtime</h3>
          Python 2.7 is no longer supported, and we need to convert.
              </div>
              <div class="fourcolside post">
                  <h3>Convert metagame to python 3 runtime</h3>
                  Python 2.7 is no longer supported, and we need to convert.
              </div>
              <div class="fourcolside post">
                  <h3>Convert examplegame to use pub/priv key auth</h3>
          Remove firebase auth and replace with lnurl login.
              </div>
              <div class="fourcolside post">
                  <h3>Integrate lightning deposit/withdraw</h3>
                  Remove braintree payments, and replace with lightning.
              </div>
              <div class="fourcolside post">
                  <h3>Remove uetopia references throughout</h3>
          uetopia is mentioned throughout the codebase, and we need to replace it.
              </div>
              <div class="fourcolside post">
                  <h3>Sunsetting uetopia</h3>
                  The uetopia website and legacy functionality will be retained until January 2024.  Users and games should run a backend instance or find an alternative before this time.
              </div>
              <div class="fourcolside post">
                  <h3>Community Discord</h3>
                  <a href="https://discord.gg/QXQkq4dhdx">Discord Server</a> up and running.  Stop by and chat about games.
              </div>
  
              <div class="clear"></div>
          </div>
  
  
          <div class="divider"></div>
          <div id="navigate">
              <div class="tag">Navigate</div>
              <div class="fourcolside">
                  <h3>Repositories</h3>
                  <ul>
                      <li><a href="https://github.com/unlockd-gg/ExampleGame">Example Game</a></li>
                      <li><a href="https://github.com/unlockd-gg/online-subsystem">Unreal Engine Plugin</a></li>
                      <li><a href="https://github.com/unlockd-gg/metagame">Metagame</a></li>
                      <li><a href="https://github.com/unlockd-gg/backend">Backend</a></li>
                      <li><a href="https://github.com/unlockd-gg/UnrealEngine">Unreal Engine</a></li>
                      <li><a href="https://github.com/unlockd-gg/socketIO-server">SocketIO Server</a></li>
            <li><a href="https://github.com/unlockd-gg/launcher">Launcher</a></li>
                  </ul>
              </div>
              <div class="fourcolside">
                  <h3>Social</h3>
                  <ul>
                      <li><a href="https://discord.gg/QXQkq4dhdx">Discord</a></li>
                      <li><a href="https://twitter.com/ed_unlockd">Twitter</a></li>
                  </ul>
              </div>
              <div class="fourcolside">
                  <h3>Support</h3>
                  <ul>
                      <li><a href="/img/btc_donations.png">bitcoin donations</a></li>
                      <li><a href="https://getalby.com/element.png">lightning donations</a></li>
  
                  </ul>
              </div>
              <div class="fourcolside">
                  <h3>uetopia/legacy</h3>
                  <ul>
                      <li><a href="https://uetopia.com">Backend</a></li>
                      <li><a href="https://github.com/uetopia/">Repositories</a></li>
                  </ul>
              </div>
              <div class="clear"></div>
          </div>
          <div class="clear"></div>
          <div class="divider"></div>
          <div class="fourcolside">&nbsp;</div><div class="fourcolside">&nbsp;</div><div class="fourcolside">&nbsp;</div><div class="fourcolsidesmall">This page designed and coded by <a href="http://www.gregponchak.com">Greg Ponchak</a>.</div>
      </div>
  
  

  
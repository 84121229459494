<p>unlockd.gg is an open source project.  This website is a live demo, and contains functionality that can be used by game developers, and players.</p>

<h1>You are a game developer</h1>

<p>As a game developer, you can deploy your own version of this website (backend) and configure it however you like.  You make your own decisions about fee structure and costs of operation.</p>

<p>This website uses a 5% fee on game income to help cover our backend expenses.  (if you don't agree with this, you can run your own)</p>

<p>You can configure your game to charge players for various things, and this is totally up to you.  You pay 5% on any income through these sources.</p>

<p>You pay for dedicated server hosting uptime through the game wallet if used.  unlockd does not mark this up, and the game is only charged for what is used.  </p>

<h1>You are a player</h1>

<p>Deposits and withdrawals, (will be) done over lightning, and there are no additional fees associated with this. You can deposit via lightning, play a few matches, then withdraw.  You pay 5% to unlockd for any game transactions.  Buying a skin for example, 5% of that transaction is a fee, which is collected by unlockd, and the other 95% goes to the game.  </p>